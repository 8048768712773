import { NavLink, Outlet } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

function Nav() {
    return (<>

        <nav className="navbar bg-primary navbar-expand-lg bg-body-tertiary body-font">
            <div className="container-fluid p-4">
                <NavLink className="navbar-brand" to="/UK/Home"><img src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz_247_logo.png" className="img-fluid" alt="Hertz24x7"/></NavLink>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/UK/Home"><b>Home</b></NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/UK/Howitworks"><b>How it works</b></NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/UK/Locations"><b>Locations</b></NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/UK/BusinessSolution"><b>Business Solutions</b></NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/UK/FAQ"><b>FAQs</b></NavLink>
                        </li>
                    </ul>

                    
                    <Button href='https://booking.hertz247.com/' variant="warning">Book Now</Button>{' '}
                </div>
            </div>
           
        </nav>
        <hr/>
        <div className='container mt-2'>
            <Outlet />
        </div>
    </>);

}
export default Nav;