import React from 'react';
import Map from './Map';
import { useJsApiLoader } from '@react-google-maps/api';



function BodyLocations() {

  
    const { isLoaded } = useJsApiLoader({
        id: 'AIzaSyAIv9qNXHisOHXq2gjEx8xUQkyfzyJWdqs',
        googleMapsApiKey: "AIzaSyAIv9qNXHisOHXq2gjEx8xUQkyfzyJWdqs"
      });
    
    return (<>
        <div className="container-fluid p-4 body-font">
            <div className="row">
                <div className="col-xs-12 col-md-6 py-md-5">
                    <p className="h6"><b>Locations</b></p>
                    <p className="h1"><b>ALWAYS NEARBY</b></p>
                    <p>With over 700 locations across UK and Europe there is always a Hertz 24/7® vehicle nearby. </p>
                    <p>Find your local vehicle with this map.</p>
                </div>
            </div>
            <Map isLoaded={isLoaded}/>
            <div className="row">
                <div className="col-xs-12 py-md-5" style={{ textAlign: 'left' }}>
                    <h1 className="display-5"><b>Our Locations</b></h1>
                    <p>With over 700 locations across Europe,a Hertz 24/7® car or van  is closer than you think.</p>
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>United Kingdom (300 Locations)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600020009&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Manchester</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902024006&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>London</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>France (20 Locations)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-fr19100400001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Paris</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-fr19110500007004&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Marseilles</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Germany (280 Locations)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-de18112800001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Berlin</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-de15102900001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Frankfurt</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Italy (75 Locations)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-it17072800007000&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Rome</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-it17072800005002&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Milan</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Portugal (39 Locations)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-pt21051200001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Lisbon</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-pt17062200003001&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Porto</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row shadow-lg p-3 mb-5 bg-body rounded" >
                <a href='https:/l.ead.me/beWm77'><img className="img-fluid rounded-4" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/LocationBannerEN.png" alt='' style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} /></a>
            </div>
        </div>
    </>
    );
}
export default BodyLocations;