import { NavLink, Outlet } from 'react-router-dom';

function Nav() {
    return (<>

        <nav className="navbar bg-primary navbar-expand-lg bg-body-tertiary body-font">
            <div className="container-fluid p-4">
                <NavLink className="navbar-brand" to="/lhcarpool/DE/Home"><img src="https://images.hertz.com/content/dam/hod/images/en-gb/static/LH247logo.png" className="img-fluid" alt="Hertz24x7"/></NavLink>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/lhcarpool/DE/Home"><b>Startseite</b></NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/lhcarpool/DE/Howitworks"><b>Wie es funktioniert</b></NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/lhcarpool/DE/FAQ"><b>FAQs</b></NavLink>
                        </li>
                    </ul>

                    
                    
                </div>
            </div>
           
        </nav>
        <hr/>
        <div className='container mt-2'>
            <Outlet />
        </div>
    </>);

}
export default Nav;